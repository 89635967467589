import { useMemo } from 'react';

import { useGetImageQuery } from 'core/services/imageApi';
import { Indexes } from 'core/models/tableData';
import { base64Image } from 'core/utils/image';

interface Props {
  type: 'URL' | 'FILE';
  urlImage?: string;
  instance?: string;
  field?: string;
  indexes?: Indexes[];
  height?: number;
  width?: number;
  id?: string;
  defaultImage?: string;
}

const useImage = ({
  type,
  urlImage,
  instance,
  indexes,
  field,
  height,
  width,
  id,
  defaultImage,
}: Props) => {
  const { isLoading, data } = useGetImageQuery(
    {
      indices: indexes,
      instancia: instance,
      campo: field,
      altura: height,
      largura: width,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !id || !indexes || !instance || type === 'URL',
    },
  );

  const image = useMemo(() => {
    if (isLoading) return '/assets/placeholder.svg';

    if (type === 'FILE') return base64Image(data?.imagem) || defaultImage;

    return urlImage || defaultImage;
  }, [type, data, urlImage, defaultImage, isLoading]);

  return {
    image,
    isLoading,
  };
};

export default useImage;
