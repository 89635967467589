import React from 'react';
import {
  Button,
  Divider,
  Stack,
  Typography,
  Tooltip,
  useTheme,
} from '@mui/material';

import DynamicServerImage from 'components/elements/DynamicServerImage';
import Iconify from 'components/elements/Iconify';
import TextMaxLine from 'components/elements/TextMaxLine';
import { DEFAULT_PRODUCT_IMAGE } from 'core/config';
import useCart from 'core/hooks/useCart';
import useCartItem from 'core/hooks/useCartItem';
import useOrder from 'core/hooks/useOrder';
import useToggle from 'core/hooks/useToggle';
import Label from 'components/elements/Label';
import { fCurrencyBRL } from 'core/utils/formatNumber';
import { TAG_COLOR } from 'theme/palette';
import { formatProjectValues } from 'core/utils/cart';
import ConfirmCartItemRemove from '../ConfirmCartItemRemove';
import Discount from '../Discount';
import DiscountField from '../DiscountField';
import QuantitySelector from '../QuantitySelector';

import { CartItemProps } from './props';

const CartItem: React.FC<CartItemProps> = ({ index, item }) => {
  const { product } = item;
  const { orderConfig } = useOrder();
  const {
    palette: { primary },
  } = useTheme();

  const [openConfirm, toggleOpenConfirm] = useToggle();
  const { removeItem } = useCart();
  const {
    alert,
    calculatingDiscount,
    calculatingValues,
    cartItem,
    error,
    modifyingProject,
    resetProduct,
    updateCartItem,
    updateInCart,
  } = useCartItem(item, index, undefined, undefined, toggleOpenConfirm);

  const projectValues = formatProjectValues(
    orderConfig,
    cartItem.price.calculadora?.projeto || [],
  );

  const labelUnitsSymbols = {
    MILIGRAMA: 'mg',
    GRAMA: 'g',
    KILO: 'Kg',
    TONELADA: 't',
    METRO: 'm',
    CENTIMETRO: 'cm',
    MILIMETRO: 'mm',
  };

  const handleConfirmDelete = () => {
    removeItem(index);
    toggleOpenConfirm();
  };

  const handleCancelDelete = () => {
    resetProduct();
    toggleOpenConfirm();
  };

  const Actions = (
    <>
      {cartItem.unit?.seletor === 'PROJETO' ? (
        <Tooltip
          title="Quer adicionar mais unidades deste produto? Volte para a página do
          produto e faça personalização para novo item."
          placement="top"
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="caption">Produto personalizado</Typography>
            <Iconify
              ml="5px"
              height={16}
              width={16}
              color="#22C55E"
              icon="material-symbols:info-outline"
            />
          </Stack>
        </Tooltip>
      ) : (
        <>
          <QuantitySelector
            onChange={(prop, newValue) => updateCartItem(prop, newValue)}
            cartItem={cartItem}
            action={updateInCart}
            triggerButtonText="Atualizar"
            actionButtonText="Atualizar item no carrinho"
            modifyingItem={modifyingProject}
            resetProduct={resetProduct}
            disabled={calculatingValues}
            error={error}
            alert={alert}
          />

          {orderConfig && (
            <Discount
              discountType={cartItem.discountType}
              onChangeDiscountType={newValue =>
                updateCartItem('discountType', newValue)
              }
              orderConfig={orderConfig}
              discountPercent={
                <DiscountField
                  name="discountPercentage"
                  value={cartItem.discountPercentage}
                  disabled={calculatingDiscount === 'discountValue'}
                  loading={calculatingDiscount === 'discountValue'}
                  onChange={value =>
                    updateCartItem('discountPercentage', value)
                  }
                  endAdornment="%"
                  sx={{ flex: 1 }}
                />
              }
              discountValue={
                <DiscountField
                  name="discountValue"
                  value={cartItem.discountValue}
                  disabled={calculatingDiscount === 'discountPercentage'}
                  loading={calculatingDiscount === 'discountPercentage'}
                  onChange={value => updateCartItem('discountValue', value)}
                  endAdornment="R$"
                  sx={{ flex: 1 }}
                />
              }
            />
          )}
        </>
      )}

      <Button
        color="error"
        startIcon={<Iconify icon="ic:round-delete-outline" />}
        onClick={toggleOpenConfirm}
        size="small"
      >
        Remover
      </Button>
    </>
  );

  return (
    <>
      <Stack gap={2} flex={1}>
        <Stack
          direction="row"
          sx={theme => ({
            gap: 3,
            [theme.breakpoints.down('sm')]: {
              gap: 0.25,
            },
          })}
          flex={1}
        >
          <Stack
            position="relative"
            width={100}
            height={100}
            alignItems="center"
            justifyContent="center"
          >
            <DynamicServerImage
              id={product?.id || cartItem.productId}
              type={product?.IMAGEM_ARQUIVO ? 'FILE' : 'URL'}
              urlImage={product?.IMAGEM_URL}
              indexes={product ? product['@original'].indices : undefined}
              field="IMAGEM"
              instance="Produto"
              defaultImage={DEFAULT_PRODUCT_IMAGE}
              alt="Imagem"
              sx={{
                borderRadius: 1,
                position: 'absolute',
                height: '100%',
                objectFit: 'cover',

                '& img': {
                  width: 'auto',
                  height: '100%',
                  objectFit: 'contain',
                },
              }}
            />
          </Stack>

          <Stack
            sx={theme => ({
              gap: 2,
              [theme.breakpoints.down('sm')]: {
                gap: 0.25,
              },
            })}
            direction="row"
            flex={1}
          >
            <Stack flex={1}>
              <Stack>
                <Stack direction="row" spacing={1}>
                  {(cartItem.tag || product?.isPromo) && (
                    <Label
                      color={TAG_COLOR.main}
                      sx={{
                        maxWidth: 88,
                        zIndex: 100,
                        backgroundColor: TAG_COLOR.bg,
                        minHeight: 24,
                      }}
                    >
                      {cartItem.tag ? cartItem.tag : 'Promo'}
                    </Label>
                  )}
                  {product?.TAG && (
                    <Label
                      color={TAG_COLOR.main}
                      sx={{
                        maxWidth: 88,
                        zIndex: 100,
                        backgroundColor: TAG_COLOR.bg,
                        minHeight: 24,
                      }}
                    >
                      {product?.TAG}
                    </Label>
                  )}
                </Stack>
                <TextMaxLine variant="subtitle2">
                  {cartItem.productName || product?.name || ''}
                </TextMaxLine>

                {cartItem.control &&
                  cartItem.productGrid &&
                  cartItem.variation && (
                    <Stack direction="row">
                      <Typography variant="subtitle2" color="text.secondary">
                        {cartItem.productGrid.descricaoV1}:{' '}
                        {cartItem.variation.description}
                        {' | '}
                        {cartItem.productGrid.descricaoV2}:{' '}
                        {cartItem.variation.variation.descricao}
                      </Typography>
                    </Stack>
                  )}
              </Stack>

              <Stack paddingTop="12px" mt="auto">
                <Stack>
                  {orderConfig?.campoFixosProjeto && (
                    <>
                      {Object.entries(projectValues).map(
                        ([fieldName, fieldValue]) => (
                          <Typography
                            key={fieldName}
                            variant="caption"
                            color="grey.500"
                          >
                            {fieldName}: {String(fieldValue)}{' '}
                            {fieldName === 'Quantidade'
                              ? 'un'
                              : labelUnitsSymbols[
                                  cartItem.unit?.descricao || ''
                                ]}
                          </Typography>
                        ),
                      )}
                    </>
                  )}
                  {orderConfig?.exibeEstoque && (
                    <Typography variant="caption" color="grey.500">
                      Estoque: {cartItem.stock}
                    </Typography>
                  )}
                  {!!(
                    cartItem.price.unitaryValue &&
                    cartItem.price.unitaryValue !== 0 &&
                    cartItem.price.value !== cartItem.price.unitaryValue
                  ) && (
                    <Typography
                      variant="caption"
                      color="grey.500"
                      style={{ textDecoration: 'line-through' }}
                    >
                      {fCurrencyBRL(cartItem.price.unitaryValue)}
                    </Typography>
                  )}

                  <Typography variant="caption" color="grey.500">
                    {fCurrencyBRL(cartItem.price.value)} /{' '}
                    {cartItem.unit?.descricao}
                  </Typography>
                </Stack>

                <Typography variant="subtitle1">
                  {fCurrencyBRL(cartItem.price.totalValue)}
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={1.5} margin="auto" ml="auto" maxWidth={240} flex={1}>
              {Actions}
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      <ConfirmCartItemRemove
        open={openConfirm}
        onClose={handleCancelDelete}
        item={cartItem}
        onClickRemove={handleConfirmDelete}
      />
    </>
  );
};

export default CartItem;
